$width_topnav_button: 100px;
$width_sidenav: 180px;
$downloadIconLeft: 190px;

$light1: #aaaaaa;
$light2: #fff;
$dark1: #212121;
$dark2: #222;

//div:               background: radial-gradient(circle, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0.5) 100%);
//btn:               background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
//btn-border:        border: 2px outset #aaaaaa;
//btn-active-border: border: 3px outset #222;
//btn-active:        background: radial-gradient(circle, rgba(150, 150, 150, 0.8) 0%, rgba(150, 150, 150, 0.3) 100%);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Calibri", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif !important;
  background-color: #ececec;
}
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .loginForm {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  .loginForm h2 {
    text-align: center;
  }
  .loginForm label {
    display: block;
    margin-top: 10px;
  }
  .loginForm input[type="text"],
  .loginForm input[type="password"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .loginForm button {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 15px;
  }
  .loginForm button:hover {
    background-color: #45a049;
  }
}
.top-nav {
  // background-color: $bg_topnav;
  background: radial-gradient(circle, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0.5) 100%);
  min-height: 50px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
      border: 2px outset #aaaaaa;
      width: $width_topnav_button;
      margin: 10px 10px 10px 10px;

      a {
        display: block;
        padding: 10px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  li .active {
    border: 3px outset #222;
    background: radial-gradient(circle, rgba(150, 150, 150, 0.8) 0%, rgba(150, 150, 150, 0.3) 100%);
  }
}
.side-nav {
  // position:relative;
  background: radial-gradient(circle, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0.5) 100%);
  //background-color: $bg_sidenav;
  width: $width_sidenav;
  min-width: $width_sidenav;
  // overflow-y: auto;
  padding-bottom: calc(100vh - 2 * (10px + 7px));
  h3 {
    text-align: left;
    margin-left: 15px;
    color: #fff;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
      border: 2px outset #aaaaaa;
      width: 90%;
      margin: 15px auto 15px auto;

      a {
        display: block;
        padding: 5px;
        color: #fff;
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  li .active {
    border: 3px outset #222;
    background: radial-gradient(circle, rgba(150, 150, 150, 0.8) 0%, rgba(150, 150, 150, 0.3) 100%);
  }
  img {
    // position: absolute;
    // bottom: 100px;
    width: 100%;
    height: 100%;
  }
  select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
}
.downloadIcon {
  position: fixed;
  top: 85px;
  left: $downloadIconLeft;
  z-index: 1000;
  cursor: pointer;
}
.csv-box {
  position: fixed;
  top: 75px;
  left: $downloadIconLeft + 50px;
  z-index: 1000;
  cursor: pointer;
  width: 200px;
  min-height: 100px;
  padding: 2px;
  background: radial-gradient(circle, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0.5) 100%);
  color: #fff;
  .item {
    width: 100%;
    border-bottom: 1px solid #cdcdcd;
    padding: 2px;
    margin: 2px;
  }
  .item:hover {
    border: 3px outset #222;
    background: radial-gradient(circle, rgba(150, 150, 150, 0.8) 0%, rgba(150, 150, 150, 0.3) 100%);
    color: #fff;
  }
}
.notificationicon {
  position: fixed;
  top: 0px;
  right: 5px;
  z-index: 1000;
  cursor: pointer;
}
.notification {
  width: 80%;
  margin: 10px auto;
  padding: 4px;
  border: 1px solid black;
  display: flex; /* Added display: flex */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 3px;
}
.loader-container {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px);
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  .loader {
    margin: 25vh auto;
    border: 15px solid #c2c0c0;
    border-top: 15px solid #222;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.info-box-container {
  z-index: 2000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* Set the width to 50% */
  // height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  justify-content: center;
  align-items: center;
  .info-box {
    background: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  button {
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}
.popup-success {
  position: fixed;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  background-color: #4caf50; /* Green background color */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Box shadow */
  z-index: 9999; /* Ensure the popup is on top */
  display: none; /* Initially hidden */
}
.popup-error {
  position: fixed;
  top: 10px; /* Adjust as needed */
  left: 10px; /* Adjust as needed */
  background-color: #da3f3f; /* Green background color */
  color: white; /* White text color */
  padding: 10px 20px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Box shadow */
  z-index: 9999; /* Ensure the popup is on top */
  display: none; /* Initially hidden */
}
.verify-box-container {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%; /* Set the width to 50% */
  // height: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(12px); /* Semi-transparent background */
  display: flex; /* Added display: flex */
  justify-content: center;
  align-items: center;
  .verify-box {
    background: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    display: flex; /* Added display: flex */
    flex-direction: column;
    width: 80%;
    max-height: 95vh;
    overflow-y: scroll;
  }

  button {
    padding: 10px;
    margin-top: 10px;
    cursor: pointer;
  }
}
.container {
  display: flex;
  align-items: flex-start;
}
.content {
  margin-top: 15px;
  flex-grow: 1;
  padding: 10px;
  overflow-x: hidden;
}

//div:               background: radial-gradient(circle, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0.5) 100%);
//btn:               background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
//btn-border:        border: 2px outset #aaaaaa;
//btn-active-border: border: 3px outset #222;
//btn-active:        background: radial-gradient(circle, rgba(150, 150, 150, 0.8) 0%, rgba(150, 150, 150, 0.3) 100%);
//rep_sales titles
.rep_sales_title {
  padding: 2px;
  text-align: center;
  margin: 0px 0 15px 0;
  width: 100%;
  border-radius: 8px;
  border: 2px outset #aaaaaa;
  color: #fff;
  background: radial-gradient(circle, rgba(33, 33, 33, 1) 0%, rgba(33, 33, 33, 0.5) 100%);
  span {
    margin-left: 10px;
  }
}
table {
  th,
  td {
    font-size: 14px;
  }
}
//rep_sales table1
.table-container {
  overflow-x: auto;
  max-height: 600px;
}
.table1 {
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
  th,
  td {
    width: 150px; /* Adjust the width as needed */
    // max-width: 280px; /* Prevent columns from exceeding the specified width */
    white-space: nowrap; /* Prevent text wrapping in cells */
    overflow: hidden;
    text-overflow: ellipsis; /* Display an ellipsis (...) for overflowed content */
    border: 1px solid #ddd;
    padding: 4px;
  }
  th:nth-child(1),
  td:nth-child(1) {
    width: 90px;
  }
  .table1_sticky1 {
    position: sticky;
    left: 0;
    backdrop-filter: blur(12px);
    z-index: 1;
    background: rgba(230, 230, 230, 1);
  }

  .table1_sticky2 {
    position: sticky;
    left: 60px;
    backdrop-filter: blur(12px);
    z-index: 1;
    background: rgba(230, 230, 230, 1);
  }
  .table1_sticky1_brandTotal {
    position: sticky;
    font-weight: bold;
    left: 0;
    backdrop-filter: blur(12px);
    z-index: 1;
    background: rgba(170, 170, 170, 0.35);
  }
  .table1_sticky2_brandTotal {
    position: sticky;
    font-weight: bold;
    left: 60px;
    backdrop-filter: blur(12px);
    z-index: 1;
    background: rgba(170, 170, 170, 0.35);
  }


  thead tr:nth-child(1),
  thead tr:nth-child(2) {
    background: rgba(255, 255, 255, 1) !important;
  }

  thead tr:nth-child(4),
  thead tr:nth-child(3) {
    background: rgba(170, 170, 170, 0.45) !important;
  }

  .sdc {
    background: rgba(230, 230, 230, 1) !important;
  }
  .sdcDC {
    background: rgba(255, 255, 255, 1) !important;
  }
  .status4 {
    background: rgba(230, 230, 230, 1) !important;
  }
  .psc {
    background: rgba(255, 255, 255, 1) !important;
  }
  .yoy1 {
    background: rgba(230, 230, 230, 1) !important;
  }
  .yoy2 {
    background: rgba(255, 255, 255, 1) !important;
  }
  .yoy3 {
    background: rgba(230, 230, 230, 1) !important;
  }
  .brand_totals {
    font-weight: bold;
    background: rgba(170, 170, 170, 0.35) !important;
  }
}
.th1_sticky {
  position: sticky !important;
  top: 0 !important;
  backdrop-filter: blur(12px);
  z-index: 2 !important;
}

.th2_sticky {
  position: sticky !important;
  top: 23px !important;
  backdrop-filter: blur(12px);
  z-index: 2 !important;
}

.th3_sticky {
  position: sticky !important;
  top: 46px !important;
  backdrop-filter: blur(12px);
  z-index: 2 !important;
}

.th4_sticky {
  position: sticky !important;
  top: 70px !important;
  backdrop-filter: blur(12px);
  z-index: 2 !important;
}
//rep_sales top 10 tables
.table2 {
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
  th,
  td {
    text-align: left;
    width: 150px; /* Adjust the width as needed */
    max-width: 150px; /* Prevent columns from exceeding the specified width */
    white-space: nowrap; /* Prevent text wrapping in cells */
    overflow: hidden;
    text-overflow: ellipsis; /* Display an ellipsis (...) for overflowed content */
    border: 1px solid #ddd;
    padding: 4px;
  }
}
.generic-table {
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
  margin: auto;
  th,
  td {
    text-align: left;
    width: auto;
    max-width: none;
    white-space: nowrap; /* Prevent text wrapping in cells */
    overflow: hidden;
    text-overflow: ellipsis; /* Display an ellipsis (...) for overflowed content */
    border: 1px solid #ddd;
    padding: 0px 2px;
  }
}
// inline charts
.top10-col {
  width: 24%;
}
.graph-col {
  width: 32%;
}
.data-col {
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  min-width: 40%;
}

//row graphs
.graph-row {
  width: 100%;
  margin-bottom: 25px;
}
//all graphs
.chart-div {
  height: 300px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}
input[type="file"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  box-sizing: border-box;
}
input[type="submit"] {
  background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
input[type="submit"]:hover {
  background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
}
select {
  width: 100%;
  padding: 6px;
  font-size: 16px;
}

.toggle-container {
  display: inline-block;
  cursor: pointer;
}

.toggle-indicator {
  display: inline-block;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: red; /* Initial color for 'off' state */
  transition: background-color 0.3s ease;
}

.toggle-indicator.on {
  background-color: green; /* Color for 'on' state */
}

.popup {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  input {
    width: 200px;
    padding: 4px;
    margin: 5px auto;
  }
}
.popup-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

// @include theme($theme: sidenav_buttons);
@mixin theme($theme: sidenav_buttons) {
}
//@extends %name;
%name {
  border: 1px solid #000;
  background: radial-gradient(circle, rgba(170, 170, 170, 1) 0%, rgba(170, 170, 170, 0.5) 100%);
}
