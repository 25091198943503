.hide{
    display:none;
}
.bold{
    font-weight:bold;
}
.center{
    text-align:center;
}


.row-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.col-container {
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    width: 100%;
}

.col{
    // max-width: 400px;
    margin: 15px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:8px;
    min-width:40%;
}
.row{
    margin: 15px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap:8px;
    min-width:40%;
}

.table{
    width: 100%;
    white-space: nowrap;
    border-collapse: collapse;
    th,
    td {
      font-size: 14px;
      width: 150px; 
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis; 
      border: 1px solid #ddd;
      padding: 4px;
    }
}

.descriptionInput{
    width: 320px;
    box-sizing: border-box;
}